import React, { useState, useEffect } from 'react'

const CustomInstallPromptContext = React.createContext<{
  state: {
    prompt: {
      prompt: () => void
      userChoice: () => Promise<string>
    } | null
  },
  actions: {

  }
}>({
  state: { prompt: null }, actions: {}
})

export const useInstallPrompt = () => React.useContext(CustomInstallPromptContext).state.prompt

export const CustomInstallPromptProvider = ({ children }: { children: React.ReactNode }) => {
  const [customInstallPrompt, setCustomInstallPrompt] = useState<any>(null)
  useEffect(() => {
    const callback = (e: {
      prompt: () => void
      userChoice: () => Promise<string>
      preventDefault: () => void
    }) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault()
      // Stash the event so it can be triggered later.
      setCustomInstallPrompt(e)
    }
    window.addEventListener('beforeinstallprompt', callback as any)
    return () => window.removeEventListener('beforeinstallprompt', callback as any)
  }, [])

  return <CustomInstallPromptContext.Provider value={{
    state: {
      prompt: customInstallPrompt
    },
    actions: {
    }
  }}>
    {children}
  </CustomInstallPromptContext.Provider>
}
