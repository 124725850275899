import { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const useAuthenticationStatus = () => {
  const [dbUpdated, setValue] = useState<boolean>(false)
  const [auth, setAuth] = useState(!!firebase.auth().currentUser)
  useAuthCallback(setAuth)
  useEffect(() => {
    let cleanup: () => void
    const currentUser = firebase.auth().currentUser
    if (currentUser !== null) {
      const reference = firebase.app().firestore().collection('users').doc(currentUser.uid).onSnapshot(doc => {
        setValue(doc.exists)
      })
      cleanup = () => {
        reference()
      }
    }
    return () => {
      if (cleanup) cleanup()
    }
  }, [auth])
  return auth && dbUpdated
}

const useAuthCallback = (callback: (isAuth: boolean) => void) => {
  useEffect(() => {
    const onAuthChange = firebase.auth().onAuthStateChanged((user) => {
      callback(Boolean(user))
    })
    return () => {
      onAuthChange()
    }
  }, [callback])
}

export { useAuthenticationStatus, useAuthCallback }
