import * as dotenv from 'dotenv'

import React from 'react'
import ReactDOM from 'react-dom'

import './i18n'
import * as serviceWorker from './serviceWorker'
import firebase from './setupFirebase'
import pixel from './setupPixel'
import App from './App'
import { SettingsProvider } from './SettingsProvider'
import { CustomInstallPromptProvider } from './PWAInstallPromptProvider'

dotenv.config()
serviceWorker.register()
firebase.init()
pixel.init()

ReactDOM.render((
  <CustomInstallPromptProvider>
    <SettingsProvider>
      <App />
    </SettingsProvider>
  </CustomInstallPromptProvider>
), document.getElementById('root'))