import ReactPixel from 'react-facebook-pixel'

const options = {
  autoConfig: true, 	// set pixel's autoConfig
  debug: false, 		// enable logs
}

const init = () => {
  ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID || '', undefined, options)
}

export default {
  init
}