import firebase from 'firebase/app'
import "firebase/messaging"
import "firebase/functions"

const devConfig = {
  apiKey: 'AIzaSyClLpY8vCQ2ABFR_-Oymwkr1_zx5gM6jSY',
  authDomain: 'betzz-dev.firebaseapp.com',
  databaseURL: 'https://betzz-dev.firebaseio.com',
  projectId: 'betzz-dev',
  storageBucket: 'betzz-dev.appspot.com',
  messagingSenderId: '65809007495',
  appId: '1:65809007495:web:01fe1133d2a2e809500922',
  measurementId: 'G-M5E82CMJ1M'
}

const handleMessaging = () => {
  // Update here if necessary
  messaging.onMessage((payload: any) => {
    // tslint:disable-next-line
    console.log("message arrived")
    // ...
  })
}

const fetchChallengeCallURL = () => {
  let url = null
  if (process.env.REACT_APP_DEPLOY_TARGET === 'qa') {
    url = `https://europe-west3-stakez-qa.cloudfunctions.net/challengeCall/`
  }
  else if (process.env.REACT_APP_DEPLOY_TARGET === 'dev') {
    url = `https://europe-west3-betzz-dev.cloudfunctions.net/challengeCall/`
  }
  else if (process.env.REACT_APP_DEPLOY_TARGET === 'production') {
    url = 'https://europe-west3-stakez-prod.cloudfunctions.net/challengeCall/'
  }
  else {
    url = `https://europe-west3-betzz-dev.cloudfunctions.net/challengeCall/`
  }
  return url
}

const devFcmPublicKey = "BOMx_h6IYqfv4cuXSwrhMvaI2_9kLHaXjJ72OrV3xNs0P7m41dEO65SBRca_d4k8BbWqGrfIcoSA4_KYAqHl0-I"
// const qaFcmPublicKey = "BNoTHKsYJ8LKI3dws8rTCB5yb0jDPVPn_15Mgn5Gmg-R0IHr7EXLdfTvmBNBe5ng_Tm-Z62lLm4JzQGFJeKsLic"
// const prodFcmPublicKey = "BOGKlSQpcskqKuqKNFuYr9WoINGzBTLHgI91A6S-bCgJ8CJtlWTBfL3lfYqoKw5Zzq6-NI4pFmMuHClULwapcc8"
let messaging: firebase.messaging.Messaging
const init = () => {
  const firebaseConfig = process.env.REACT_APP_FIREBASE_CONFIG !== '{FIREBASE_CONFIG}' ? JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG!) : devConfig

  if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig)
    // ReactGA.initialize(firebaseConfig.measurementId)
    firebase.functions()
    if (firebase.messaging.isSupported()) {
      messaging = firebase.messaging()
      messaging.usePublicVapidKey(getFCMPublicKey())
      handleMessaging()
    }
  }
}

const getFCMPublicKey = () => {
  const fcmKey = process.env.REACT_APP_FCM_KEY === '{FCM_KEY}' ? devFcmPublicKey : process.env.REACT_APP_FCM_KEY!
  return fcmKey
}

export default {
  init
}

export { messaging, fetchChallengeCallURL }