import React, { useState, useEffect, Suspense } from 'react'
import { CssBaseline, createMuiTheme } from '@material-ui/core'

import { ThemeProvider } from '@material-ui/core/styles'

import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

import { useSettingsContext } from './SettingsProvider'
import { useTranslation } from 'react-i18next'
import { tokenHandler } from './utils/firebaseCloudMessaging'
import CookieConstentDialog from './Terms/CookieConsentDialog'
import useWindowSize from './utils/useWindowSize'
import { UserDataProvider } from './UserDataProvider'

const setupPersistence = () => {
  const uid = firebase.auth().currentUser?.uid
  if (uid) {
    const userStatusDatabaseRef = firebase.database().ref('/status/' + uid)
    const isOfflineForDatabase = {
      state: 'offline',
      last_changed: firebase.database.ServerValue.TIMESTAMP,
    }

    const isOnlineForDatabase = {
      state: 'online',
      last_changed: firebase.database.ServerValue.TIMESTAMP,
    }
    const callback = (snapshot: firebase.database.DataSnapshot) => {
      // If we're not currently connected, don't do anything.
      if (snapshot.val() === false) {
        return
      }
      userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(() => {
        userStatusDatabaseRef.set(isOnlineForDatabase)
      })
    }
    firebase.database().ref('.info/connected').on('value', callback)

    return () => firebase.database().ref('.info/connected').off('value', callback)
  }
}

const AsyncLandingpage = React.lazy(() => import('./Landingpage/LandingPageRouter'))
const Landingpage = () => (
  <React.Suspense fallback={null}>
    <AsyncLandingpage />
  </React.Suspense>
)

const AsyncAppRouter = React.lazy(() => import('./Router'))
const AppRouter = () => (
  <React.Suspense fallback={null}>
    <AsyncAppRouter />
  </React.Suspense>
)

function App() {
  const { i18n } = useTranslation()
  const { state: { darkmode, locale } } = useSettingsContext()
  const theme = React.useMemo(
    () => createMuiTheme({
      palette: {
        type: localStorage.getItem('auth') !== null && darkmode === 'light' ? 'light' : 'dark',
        primary: {
          main: '#f29898'
        },
        background: {
          default: '#202020',
          paper: '#303030'
        }
      },
      overrides: {
        MuiButton: {
          // Name of the rule
          root: {
            // Some CSS
            borderRadius: '3em',
          },
        },
      }
    }),
    [darkmode],
  )

  useEffect(() => {
    i18n.changeLanguage(locale)
  }, [locale, i18n])
  const [wasAuthenticatedBefore, setAuthenticated] = useState<boolean>(localStorage.getItem('auth') !== null)
  const [loadingAuthState, setLoading] = useState<boolean>(wasAuthenticatedBefore)

  /**
   * We use the localStorage variable 'auth' to directly show the LandingPage or HomePage depending on wether
   * the user was already logged in before. That way we avoid a short "blink" display of the LandingPage before navigating to
   * the HomePage (also avoids loading of the LandingPage)
   */
  useEffect(() => {
    let cleanupPersistence: (() => void) | undefined
    const onAuthChange = firebase.auth().onAuthStateChanged((user) => {
      setLoading(Boolean(user))
      if (user) {
        setAuthenticated(true)
        localStorage.setItem('auth', 'true')
        tokenHandler(user)
        cleanupPersistence = setupPersistence()
      } else {
        setAuthenticated(false)
        localStorage.removeItem('auth')
      }
    })
    return () => {
      onAuthChange()
      if (cleanupPersistence) {
        cleanupPersistence()
      }
    }
  }, [])
  const [, height] = useWindowSize()
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div style={{
        textAlign: 'center',
        alignContent: 'center',
        backgroundColor: theme.palette.background.default,
        minHeight: height - 18 + 'px',
        width: '100%',
        padding: 'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)'
      }}>
        {!loadingAuthState
          ? (
            <Landingpage />
          ) : (
            <UserDataProvider>
              <AppRouter />
            </UserDataProvider>
          )}
      </div>
      <CookieConstentDialog />
    </ThemeProvider>
  )
}

const AppWithSuspense = () => (
  <Suspense fallback={null}>
    <App />
  </Suspense>
)

export default AppWithSuspense
