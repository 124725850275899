import firebase, { FirebaseError } from "firebase/app"
import 'firebase/firestore'
import { messaging } from "../setupFirebase"

const tokenHandler = (user: firebase.User) => {
  if (firebase.messaging.isSupported()) {
    getFcmToken(user)
    messaging.onTokenRefresh(refreshToken)
  }
}

const getFcmToken = (user: firebase.User) => {
  // Get Instance ID token. Initially this makes a network call, once retrieved
  // subsequent calls to getToken will return from cache.
  messaging
    .getToken()
    .then((currentToken: string) => {
      if (currentToken) {

        if (user != null) {
          const reference = firebase.firestore().doc(`users/${user?.uid}`)
          reference.update({ fcm: firebase.firestore.FieldValue.arrayUnion(currentToken) })
        }
      }
    })
    .catch((err: FirebaseError) => {
      // tslint:disable no-empty
    })
}

const refreshToken = () => {
  const user = firebase.auth().currentUser
  messaging.getToken().then((refreshedToken: string) => {
    if (user != null) {
      const reference = firebase.firestore().doc(`users/${user?.uid}`)
      reference.update({ fcm: firebase.firestore.FieldValue.arrayUnion(refreshedToken) })
    }
    // Indicate that the new Instance ID token has not yet been sent to the
    // app server.
    // setTokenSentToServer(false);
    // Send Instance ID token to app server.
    // sendTokenToServer(refreshedToken);
    // ...
  })
    .catch((err: FirebaseError) => {
      // tslint:disable no-empty
    })
}

export { tokenHandler }
