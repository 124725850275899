import React from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import { useDatabaseValue, useDatabaseDocument } from './utils/firebaseFirestoreHooks'
import { User } from './Types/User'

export type Balance = {
  amount: number,
  stakezCoins: number,
  representStakezCoins: string,
  representAmount: string,
  stakezSuffix: string,
  multiplcator: number
}

const UserDataContext = React.createContext<{
  state: {
    user: (User | null | undefined), balance: (Balance | null), activeCall: any
  }
}>({
  state: {
    user: undefined,
    balance: null,
    activeCall: undefined
  }
})

export const useUserDataContext = () => React.useContext(UserDataContext)

const BalanceFetcher = ({ balanceRef, setter }: { balanceRef: string, setter: (balance: Balance | null) => void }) => {
  const balancePath = React.useCallback(() => balanceRef, [balanceRef])
  const balance = useDatabaseValue<null | Balance>(balancePath, null)
  React.useEffect(() => {
    let roundedBalance
    if (balance) {
      const amount = Number((Math.floor(balance.amount * 100) / 100).toFixed(2))
      const stakezCoins = amount * 100
      roundedBalance = { ...balance, multiplcator: 100, amount, stakezCoins, representStakezCoins: stakezCoins.toFixed(0), representAmount: amount.toFixed(0), stakezSuffix: "sz" }
    } else {
      roundedBalance = balance
    }
    setter(roundedBalance)
  }, [balance, setter])

  return <div />
}

const ChallengeCallFetch = ({ callRef, setter }: any) => {
  const path = React.useCallback(() => callRef, [callRef])
  const invitationsCallback = useDatabaseDocument(path)
  React.useEffect(() => {
    setter(invitationsCallback)
  }, [invitationsCallback, setter])
  return <div />
}

export const UserDataProvider = ({ children }: { children: React.ReactNode }) => {
  const [balance, setBalance] = React.useState<null | Balance>(null)
  const [activeCall, setActiveCall] = React.useState<null | undefined | any>(undefined)
  const callback = React.useCallback((currentUser: firebase.User | null) => `/users/${currentUser?.uid}`, [])
  const user = useDatabaseDocument<null | User>(callback)

  return (
    <UserDataContext.Provider value={{
      state: {
        user, balance, activeCall: user?.activeCall?.path ? activeCall : undefined
      }
    }}>
      {user?.activeCall?.path && <ChallengeCallFetch callRef={user?.activeCall?.path} setter={setActiveCall} />}
      {user?.balance?.path && <BalanceFetcher balanceRef={user?.balance?.path} setter={setBalance} />}
      {children}
    </UserDataContext.Provider>
  )
}
