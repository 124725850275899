import { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import { useAuthenticationStatus } from './firebaseAuthHooks'

export type EmailObject = {
  email: string,
  id: string,
  ref: number,
  ranking: number,
  realRanking: number
  confirmed: boolean
}

const useDatabaseValue = <T>(path: (user: firebase.User) => string, defaultValue: T, fieldName = "", isFromServer = false): T => {
  const [value, setValue] = useState<T>(defaultValue)
  const isLoggedIn = useAuthenticationStatus()

  useEffect(() => {
    let cleanup: () => void
    const user = firebase.auth().currentUser

    if (isLoggedIn && user) {
      const reference = firebase.firestore().doc(path(user))

      cleanup = reference.onSnapshot((doc) => {

        fieldName !== "" ? setValue(doc.get(fieldName) as T) : setValue(doc.data() as T)

      })
    }
    return () => {
      if (cleanup) cleanup()
    }
  }, [isLoggedIn, path, fieldName])
  return value
}

const useDatabaseDocument = <T>(path: (user: firebase.User | null) => string, isFromServer = false): T | null | undefined => {
  const [value, setValue] = useState<T | null | undefined>(undefined)

  const isLoggedIn = useAuthenticationStatus()

  useEffect(() => {
    let cleanup: () => void
    const user = firebase.auth().currentUser
    if (isLoggedIn && user && path(user)) {
      const reference = firebase.firestore().doc(path(user))
      cleanup = reference.onSnapshot((doc) => {

        if (doc.exists) {
          setValue(doc.data() as T)
        } else {
          setValue(null)
        }

      })
    }
    return () => {
      if (cleanup) cleanup()
    }
  }, [isLoggedIn, path])

  return value
}

const useDatabaseCollection = <T>(path: (user: firebase.User) => string, query: (collectionRef: firebase.firestore.CollectionReference) => firebase.firestore.Query, limit = 10, isFromServer = false): [T[], boolean] => {
  const [value, setValue] = useState<T[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const isLoggedIn = useAuthenticationStatus()

  useEffect(() => {
    let cleanup: () => void
    const user = firebase.auth().currentUser
    if (isLoggedIn && user) {
      const reference = firebase.firestore().collection(path(user))
      cleanup = query(reference).onSnapshot((querySnapshot) => {

        const newValue: T[] = []
        querySnapshot.forEach((doc) => {

          const fetchedObject = {
            ...doc.data() as T,
            id: doc.id
          }

          newValue.push(fetchedObject as T)

        })
        setValue(newValue)
        setLoading(false)
      })
    }
    return () => {
      if (cleanup) cleanup()
    }
  }, [isLoggedIn, path, query])

  return [value, loading]
}

export {
  useDatabaseValue, useDatabaseCollection, useDatabaseDocument
}
