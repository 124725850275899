import React, { useState } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  link: {
    color: '#a25858'
  }
}))
const CookieConstentDialog = () => {
  const classes = useStyles()
  const [visible, setVisible] = useState<boolean>(localStorage.getItem('cookies_accepted') === null)
  const { t } = useTranslation()
  
  const handleAccept = () => {
    setVisible(false)
    localStorage.setItem('cookies_accepted', 'accepted')
  }
  return (<Snackbar
    open={visible}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    message={<span id='message-id'>
      {t('cookie-dialog-1')}<a className={classes.link} href='/privacy'>{t('cookie-dialog-2')}</a>{t('cookie-dialog-3')}
    </span>
    }
    action={[
      <Button
        key='accept'
        color='primary'
        size='large'
        variant={'contained'}
        onClick={handleAccept}
      >
        Ok
      </Button>
    ]}
  />)
}

export default CookieConstentDialog
